<template>
  <div class="offer-list-item">
    <div class="fake-row offer__header">
      <div class="offer-header__info">
        <h3 class="offer-header__reference" data-cy="offerReference">
          {{ $t('ctx.offers.list.item.reference') }} {{ offer.sellerCustomId || '-' }}
        </h3>
        <AdnTag
          v-if="isTechExclusive"
          shape="square"
          background-color="GREY"
          border-color="TRANSPARENT"
          text-color="GREY"
          data-test="tech-exclusive"
        >
          <IdfTextIcon icon-left="b2b-filled" :aria-hidden="true">{{ $t('ctx.offers.list.item.offre') }}</IdfTextIcon>
        </AdnTag>
        <AdnTag
          v-if="isAllowedWithoutStock"
          shape="square"
          background-color="GREY"
          border-color="TRANSPARENT"
          text-color="GREY"
          data-test="allowed-without-stock"
        >
          <IdfTextIcon icon-left="dashboard-filled" :aria-hidden="true">{{
            $t('ctx.offers.list.item.offerWithoutStock')
          }}</IdfTextIcon>
        </AdnTag>
      </div>
      <p v-if="offer.offerTechnicalInformation" data-test="tech-info" data-cy="offerTechnicalInformation">
        {{ $t('ctx.offers.list.item.internalRef') }} {{ offer.offerTechnicalInformation }}
      </p>
      <adn-status :status="validityStatus" :iconPosition="AdnStatusIconPositionEnum.RIGHT" data-test-validity-status />
    </div>
    <div class="fake-row offer-list-item__row">
      <div class="col-md-6">
        <div class="offer__content-text-header">{{ $t('ctx.offers.list.item.offerType') }}</div>
        <div class="offer__content-text-unit" data-cy="offerType">{{ offerTypeText }}</div>
      </div>
      <div class="col-md-6" data-test-validity-date-block>
        <div class="offer__content-text-header">{{ $t('ctx.offers.list.item.validityDateFrom') }}</div>
        <div>
          <span
            class="offer__content-text-unit"
            :class="validityStartDate == '-' ? 'text-center' : ''"
            data-test-validity-start-date
            data-cy="offerValidityStartDate"
            >{{ validityStartDate }}</span
          >
          <span>{{ $t('ctx.offers.list.item.to') }}</span>
          <span
            class="offer__content-text-unit"
            :class="validityEndDate == '-' ? 'text-center' : ''"
            data-test-validity-end-date
            data-cy="offerValidityEndDate"
            >{{ validityEndDate }}</span
          >
        </div>
      </div>
    </div>
    <div class="fake-row offer-list-item__row">
      <div class="col-md-6">
        <div class="offer__content-text-header">{{ $t('ctx.offers.list.item.priceType') }}</div>
        <div class="offer__content-text-unit" data-cy="offerPriceType">{{ priceTypeText }}</div>
      </div>
      <div class="col-md-6">
        <div class="offer__content-text-header" data-test-shipping-date-label>{{ productShippingStartDateLabel }}</div>
        <div>
          <span
            class="offer__content-text-unit"
            :class="shippingStartDate == '-' ? 'text-center' : ''"
            data-cy="offerShippingStartDate"
            >{{ shippingStartDate }}</span
          >
          <span>{{ $t('ctx.offers.list.item.to') }}</span>
          <span
            class="offer__content-text-unit"
            :class="shippingEndDate == '-' ? 'text-center' : ''"
            data-cy="offerShippingEndDate"
            >{{ shippingEndDate }}</span
          >
        </div>
      </div>
    </div>
    <div class="fake-row offer-list-item__row">
      <div v-if="isOfferSoldAsUnit" class="col-md-6">
        <div class="offer__content-text-header">{{ $t('ctx.offers.list.item.priceExcludingTaxes') }}</div>
        <div class="offer__content-text">
          <div class="offer__content-text-unit" data-test-unit-price-text>
            {{ unitPrice }}&nbsp;{{ $t('ctx.offers.list.item.unity') }}
          </div>
        </div>
      </div>
      <div v-else class="col-md-6">
        <div class="offer__content-text-header">{{ $t('ctx.offers.list.item.priceExcludingTaxes') }}</div>
        <div class="offer__content-text" data-cy="offerPrice">
          <div data-test-price-text class="offer__content-text-unit">{{ measurementPrice }}</div>
          <div class="offer__content-text-unit" data-test-measure-unit>&nbsp;€/{{ displayedUnit }}</div>
          <div class="offer__price-conjunction" data-test-price-conjunction>
            {{ $t('ctx.offers.list.item.priceConjunction') }}
          </div>
          <div class="offer__content-text-unit" data-test-price-conversion>
            {{ unitPrice }}&nbsp;{{ $t('ctx.offers.list.item.unity') }}
          </div>
        </div>
      </div>
      <div class="col-md-6" data-test-shipping-ways-block>
        <div class="offer__content-text-header" data-test-shipping-ways-label>{{ productShippingWaysLabel }}</div>
        <div class="offer__content-text-unit" data-test-shipping-ways-content data-cy="offerShippingWay">
          {{ logisticOffersReferences }}
        </div>
      </div>
    </div>
    <div class="fake-row offer-list-item__row" v-if="hasSegment || quota">
      <div v-if="quota.initial" class="col-md-6">
        <div class="offer__content-text-header">{{ $t('ctx.offers.list.item.quota') }}</div>
        <div class="offer__content-text" data-test-quota-value>
          <div class="offer__content-text-unit">{{ quota.remaining }}</div>
          <div>/{{ quota.initial }} {{ quotaUnit }}</div>
          <IdfButton
            class="offer__quota-detail-link"
            kind="link"
            tag="router-link"
            icon-left="info-circle-outlined"
            :route="{ name: 'quotas', hash: `#${quota.meshId}` }"
          />
        </div>
      </div>
      <div v-if="hasSegment" class="col-md-6" data-test="segment-block">
        <div class="offer__content-text-header">{{ $t('ctx.offers.list.item.segmentation') }}</div>
        <div class="offer__content-text">
          <div class="offer__content-text-unit" data-test="segment-list">{{ segments }}</div>
        </div>
      </div>
    </div>
    <div
      v-if="offer.description"
      class="fake-row offer-list-item__row"
      data-test="description-block"
      data-cy="offerDescription"
    >
      <div class="col">
        <div class="offer__content-text-header">{{ $t('ctx.offers.list.item.description') }}</div>
        <div class="offer__content-text">
          <div class="offer__content-text-unit" data-test="segment-list">{{ offer.description }}</div>
        </div>
      </div>
    </div>
    <div class="fake-row offer-list-item__row">
      <div v-if="!isPast" class="offer__btn-form" data-test="btns-action">
        <IdfButton
          kind="link"
          icon-left="edit-outlined"
          @click="showEditOfferForm()"
          data-edit-button
          data-cy="modifyOfferButton"
          >{{ $t('ctx.offers.list.item.update') }}</IdfButton
        >
        <IdfButton
          kind="link"
          icon-left="close"
          @click="showDeleteOfferModal()"
          data-test="delete-button"
          data-cy="deleteOfferButton"
          >{{ $t('ctx.offers.list.item.delete') }}</IdfButton
        >
      </div>
    </div>
    <c-confirm-modal
      ref="offerDeletionModal"
      :title="$t('ctx.offers.list.item.offerDeletion')"
      :message="$t('ctx.offers.list.item.deletionCheck')"
      :confirmText="$t('ctx.offers.list.item.delete')"
      :cancelText="$t('ctx.offers.list.item.cancel')"
      @cancel="hideOfferDeletionModal()"
      @confirm="hideOfferDeletionModal() & deleteOffer()"
      class="offer__delete-confim-modal"
    ></c-confirm-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { AdnStatusEnum } from '@b2ag/design-system/dist/components/AdnStatus/AdnStatusEnum'
import { AdnStatusIconPositionEnum } from '@b2ag/design-system/dist/components/AdnStatus/AdnStatusIconPositionEnum'
import AdnStatus from '@b2ag/design-system/dist/components/AdnStatus/AdnStatus.vue'
import { use18n } from '@invivodf/locales/src/use18n'
import { IdfButton, IdfTextIcon } from '@invivodf/ui-kit'
import CConfirmModal from '@/components/CConfirmModal/CConfirmModal.vue'
import { offerService } from '@/services'
import { defaultPriceTypes } from '@/services/price-type.const'
import { formatDate } from '@/services/date.utils'
import { measureUnit } from '../../../filters'
import defaultOfferTypes from '../../../services/offer-type.const'
import { mapMeshIdFromOffers } from '../../../domain/Quota'

const priceFormatter = new Intl.NumberFormat('en-EN', { maximumFractionDigits: 5, minimumFractionDigits: 2 })

export default {
  name: 'CVariantOfferListItem',
  components: { CConfirmModal, AdnStatus, IdfButton, IdfTextIcon },
  props: {
    measureQuantity: Number,
    measureUnit: String,
    isSoldAsUnit: Boolean,
    productKind: String,
    offer: {
      type: Object,
      default: () => {
        return {}
      },
    },
    storeNamespace: {
      type: String,
      default: () => {
        return 'variantOffers'
      },
    },
  },
  data() {
    const i18n = use18n()
    const PRODUCT_LABELS_MAP = {
      services: {
        dateLabel: i18n.t('ctx.offers.list.item.productLabels.services.interventionPeriod'),
        shippingLabel: i18n.t('ctx.offers.list.item.productLabels.services.interventionType'),
      },
      default: {
        dateLabel: i18n.t('ctx.offers.list.item.productLabels.default.deliveryDate'),
        shippingLabel: i18n.t('ctx.offers.list.item.productLabels.default.deliveryMode'),
      },
    }

    return {
      AdnStatusIconPositionEnum,
      i18n,
      PRODUCT_LABELS_MAP,
    }
  },
  computed: {
    ...mapGetters(['cooperativeId']),
    quota() {
      const [meshId] = mapMeshIdFromOffers([this.offer])
      return this.$store.getters['quota/byMeshId'](meshId)
    },
    quotaUnit() {
      return this.isSoldAsUnit ? 'unités' : measureUnit(this.measureUnit)
    },
    displayedUnit() {
      return this.offer.salesUnit ? measureUnit(this.offer.salesUnit) : measureUnit(this.measureUnit)
    },
    measurementPrice() {
      const price = this.offer.measurementPrice ?? 0
      return priceFormatter.format(price)
    },
    unitPrice() {
      const price = this.offer.unitPrice ?? 0
      return priceFormatter.format(price)
    },
    priceTypeText() {
      return (defaultPriceTypes.find((priceType) => priceType.name === this.offer.priceType) || {}).title
    },
    offerTypeText() {
      return defaultOfferTypes[this.offer.offerType]
    },
    shippingStartDate() {
      return formatDate(this.offer.shippingStartDate)
    },
    shippingEndDate() {
      return formatDate(this.offer.shippingEndDate)
    },
    validityStartDate() {
      return formatDate(this.offer.validityStartDate)
    },
    validityEndDate() {
      return formatDate(this.offer.validityEndDate)
    },
    validityStatus() {
      switch (this.offer.validityStatus) {
        case 'active':
          return AdnStatusEnum.OFFER.ACTIVE
        case 'upcoming':
          return AdnStatusEnum.OFFER.UPCOMING
        case 'past':
          return AdnStatusEnum.OFFER.PAST
        default:
          return ''
      }
    },
    logisticOffersReferences() {
      return this.offer.logisticOffers
        .map((logisticOffer) => logisticOffer.deliveryModeBusinessName)
        .sort()
        .join(', ')
    },
    productShippingStartDateLabel() {
      return this.PRODUCT_LABELS_MAP[this.productKind]
        ? this.PRODUCT_LABELS_MAP[this.productKind].dateLabel
        : this.PRODUCT_LABELS_MAP.default.dateLabel
    },
    productShippingWaysLabel() {
      return this.PRODUCT_LABELS_MAP[this.productKind]
        ? this.PRODUCT_LABELS_MAP[this.productKind].shippingLabel
        : this.PRODUCT_LABELS_MAP.default.shippingLabel
    },
    isPast() {
      return this.offer.validityStatus === 'past'
    },
    isTechExclusive() {
      return this.offer && this.offer.isTechExclusive
    },
    isAllowedWithoutStock() {
      return this.offer && this.offer.allowedWithoutStock
    },
    segments() {
      const segmentList = (this.offer && this.offer.segmentList) || []
      return segmentList.join(', ') || ''
    },
    hasSegment() {
      return this.offer && this.offer.segmentList && this.offer.segmentList.length > 0
    },
    isOfferSoldAsUnit() {
      if (this.offer && this.offer.salesUnit) {
        return this.offer.salesUnit === 'piece'
      }
      return this.isSoldAsUnit
    },
  },
  methods: {
    hideOfferDeletionModal() {
      this.$refs.offerDeletionModal.hide()
    },
    showDeleteOfferModal() {
      this.$refs.offerDeletionModal.show()
    },
    async deleteOffer() {
      try {
        // eslint-disable-next-line no-unused-expressions
        await offerService.delete(this.offer.id)
        this.$store.dispatch(`${this.storeNamespace}/deleteOffer`, this.offer)
      } catch (error) {
        this.$store.dispatch('setToast', {
          type: 'danger',
          message: this.i18n.t('ctx.offers.list.item.setToastErrorMessage'),
        })
      }
    },
    showEditOfferForm() {
      this.$emit('editOffer', this.offer.id)
    },
  },
}
</script>

<style lang="scss">
@import '../../../lib/_variables.scss';
.offer__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #d5dce3;
  padding-bottom: 10px;
}
.offer-header__info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.1rem;

  @media screen and (min-width: $idf-breakpoint-md) {
    flex-direction: row;
    align-items: center;
    row-gap: 1.6rem;
    gap: 1.6rem;
  }
}
.offer-list-item__icon {
  margin-right: 3px;
}
.offer-header__reference {
  font-size: 1.6rem;
  font-weight: 700;
  margin-bottom: 2px;
}
.offer__content-validity_status-active {
  color: var(--idf-color-success-400);
}
.offer__content-text-header {
  font-size: 16px;
  margin-bottom: 5px;
}
.offer__content-text-unit {
  font-size: 16px;
  font-weight: bold;
}
.offer-list-item .col-md-6,
.offer-list-item .col-md-4,
.offer-list-item .col {
  padding-left: 5px;
  padding-right: 5px;
}
.offer-list-item__row {
  margin-top: 16px;
}
.offer__price-conjunction {
  margin-left: 10px;
  margin-right: 10px;
}
.offer__btn-form {
  display: flex;
  gap: var(--idf-spacing-2);
  width: 100%;
}
.offer-list-item {
  margin-bottom: 1rem;
  border: 1px solid var(--idf-color-neutral-200);
  padding: 2.4rem 2.4rem;
  box-shadow: -3px 3px 5px 0px var(--idf-color-neutral-200);
}
.offer__validity-status {
  color: var(--idf-color-success-400);
}
.offer__quota-detail-link {
  padding: 0;
  line-height: unset;
  min-height: unset;
}
</style>
