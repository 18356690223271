import VueI18n from 'vue-i18n'
import { provideVue18n } from './use18n'

import fr_farmi from './farmi/fr-FR.json'
import fr_aladin from './aladin/fr-FR.json'
import fr_episens from './episens/fr-FR.json'
import en_umg_us from './umg-us/en-US.json'

import fr_default from './default/fr-FR.json'
import en_default from './default/en-US.json'

import fr_emoji from './emoji/fr-FR.json'

export const localeList = {
  aladin: {
    'fr-FR': fr_aladin,
  },
  farmi: {
    'fr-FR': fr_farmi,
  },
  episens: {
    'fr-FR': fr_episens,
  },
  umg_us: {
    'en-US': en_umg_us,
  },
  emoji: {
    'fr-FR': fr_emoji,
  },
}

export function initPlugin(locale, Vue, fallbackLocale = 'fr') {
  const customBrandName = localStorage.getItem('customLocale')
  const brandName = customBrandName || window.env.VUE_APP_CONTEXT

  Vue.use(VueI18n)

  const i18n = new VueI18n({
    messages: { ...localeList[brandName], fr: fr_default, en: en_default },
    locale,
    fallbackLocale,
  })

  provideVue18n(i18n)

  return i18n
}

export { VueI18n }
